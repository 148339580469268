.contact-us__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 12px 30px;
    border-bottom: 1px solid var(--grey-light);
    font-size: 16px;
    text-transform: uppercase;
    color: var(--gold);
    font-weight: bold;
    letter-spacing: 1px;
}

.contact-us__main {
    display: flex;
    /* padding: 12px 30px; */
    flex-wrap: wrap;
}

.contact-us_description {
    padding: 10px;
    background-color: var(--grey-light);
    border-bottom: 1px solid var(--grey-light);
    margin-bottom: 10px;
    padding-left: 15%;
    padding-right: 15%;
    width: 100%;
}

.contact-us_paragraph {
    line-height: 2;
    font-size: medium;
}

.contact-us__form-container {
    flex: 1;
    padding: 12px 30px;
}

.contact-us__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 12 * 5);
    padding: 70px;
}

.contact-us__image-outer {
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
}

.contact-us__image-outer:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.contact-us__image-outer span {
    color: var(--secondary-colour);
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    text-transform: uppercase;
    line-height: 40px;
    text-align: center;
}

.contact-us__image-outer img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.contact-us .react-tel-input .form-control {
    font-size: 12px;
    width: 100%;
    height: 50px;
}

@media screen and (max-width: 768px) {
    .contact-us_descriptionHeading {
        font-size: 2.1rem;
    }
    .contact-us__image-container {
        display: none;
    }
}