.bgimg {
  background-image: url('../../assets/images/frontpage.jpg');
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-size: 25px;
  width: 100%;
  min-height: 850px;
  background-position: 0 -150px;
  background-repeat: no-repeat;
}
  
.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

hr {
  margin: auto;
  width: 40%;
}