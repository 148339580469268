/* Dropzone */
.dropzone {
    padding: 30px;
    border: 5px dashed #7649bc;
    outline: none;
    cursor: pointer;
}
.dropzone-active {
    background-color: #f1e2ff;
    border: 5px solid #2fd7b5;
}