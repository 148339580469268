/*******************************/
/*   Grid Units                */
/*******************************/

:root {
    
    --unit-zero: 0;
    --unit-zero-quarter: calc(100% / 12 * 0.25);
    --unit-zero-half: calc(100% / 12 * 0.5);
    --unit-zero-three-quarter: calc(100% / 12 * 0.75);

    --unit-one: calc(100% / 12);
    --unit-one-quarter: calc(100% / 12 * 1.25);
    --unit-one-half: calc(100% / 12 * 1.5);
    --unit-one-three-quarter: calc(100% / 12 * 1.75);

    --unit-two: calc(100% / 12 * 2);
    --unit-two-quarter: calc(100% / 12 * 2.25);
    --unit-two-half: calc(100% / 12 * 2.5);
    --unit-two-three-quarter: calc(100% / 12 * 2.75);

    --unit-three: calc(100% / 12 * 3);
    --unit-three-quarter: calc(100% / 12 * 3.25);
    --unit-three-half: calc(100% / 12 * 3.5);
    --unit-three-three-quarter: calc(100% / 12 * 3.75);

    --unit-four: calc(100% / 12 * 4);
    --unit-four-quarter: calc(100% / 12 * 4.25);
    --unit-four-half: calc(100% / 12 * 4.5);
    --unit-four-three-quarter: calc(100% / 12 * 4.75);

    --unit-five: calc(100% / 12 * 5);
    --unit-five-quarter: calc(100% / 12 * 5.25);
    --unit-five-half: calc(100% / 12 * 5.5);
    --unit-five-three-quarter: calc(100% / 12 * 5.75);

    --unit-six: calc(100% / 12 * 6);
    --unit-six-quarter: calc(100% / 12 * 6.25);
    --unit-six-half: calc(100% / 12 * 6.5);
    --unit-six-three-quarter: calc(100% / 12 * 6.75);

    --unit-seven: calc(100% / 12 * 7);
    --unit-seven-quarter: calc(100% / 12 * 7.25);
    --unit-seven-half: calc(100% / 12 * 7.5);
    --unit-seven-three-quarter: calc(100% / 12 * 7.75);

    --unit-eight: calc(100% / 12 * 8);
    --unit-eight-quarter: calc(100% / 12 * 8.25);
    --unit-eight-half: calc(100% / 12 * 8.5);
    --unit-eight-three-quarter: calc(100% / 12 * 8.75);

    --unit-nine: calc(100% / 12 * 9);
    --unit-nine-quarter: calc(100% / 12 * 9.25);
    --unit-nine-half: calc(100% / 12 * 9.5);
    --unit-nine-three-quarter: calc(100% / 12 * 9.75);

    --unit-ten: calc(100% / 12 * 10);
    --unit-ten-quarter: calc(100% / 12 * 10.25);
    --unit-ten-half: calc(100% / 12 * 10.5);
    --unit-ten-three-quarter: calc(100% / 12 * 10.75);

    --unit-eleven: calc(100% / 12 * 11);
    --unit-eleven-quarter: calc(100% / 12 * 11.25);
    --unit-eleven-half: calc(100% / 12 * 11.5);
    --unit-eleven-three-quarter: calc(100% / 12 * 11.75);

    --unit-twelve: 100%;
}