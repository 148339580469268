/*******************************/
/*      Filter                 */
/*******************************/

.filter {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 10px 30px;
    border-bottom: 1px solid var(--grey-light);
}

.filter__filters {
    display: flex;
    align-items: center;
}

.fitler__heading {
    font-size: 22px;
    text-transform: uppercase;
    color: var(--gold);
    font-weight: bold;
    letter-spacing: 1px;
    vertical-align: middle;
    padding-left: 10px;
}

.filter__layouts {
    display: flex;
    align-items: center;
}

.filter__stats-count {
    font-size: 12px;
    letter-spacing: 1px;
    border: 1px solid var(--grey-light);
    padding: 1px 10px;
    border-radius: 4px;
    margin: 0 10px;
}

.filter__layouts-list {
    border: 1px solid var(--grey-light);
    padding: 1px 10px;
    border-radius: 4px;
}

.filter__layouts-item {
    display: inline-block;
    margin-right: 5px;
}

@media screen and (max-width: 1200px) {
    .filter {
        padding: 10px 15px;
    }
  
}

@media screen and (max-width: 768px) {
    .filter {
        display: block;
    }
    
    .fitler__heading {
        margin-bottom: 5px;
    }

    .filter__filters{
        flex: 1;
        justify-content: space-between;
    }

    .filter__posts {
        display: flex;
        flex-direction: column;
        width: 48%;
    }

    .filter__states > select {
        width: var(--unit-twelve);
    }

    .filter__stats-count {
        position: absolute;
        right: 15px;
        top: 10px;
        margin: 0;
    }

    .filter__layouts-list {
        display: none;
    }
}