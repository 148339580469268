.profile__section-content {
    width: 100%;
    padding: 0;
}

.eb-forms textarea {
    min-height: 175px;
}

.rodal--contact_modal .rodal-dialog {
    min-height: 460px;
    width: 600px !important;
    border-radius: 5px;
}

.rodal__main-container, .rodal__btn-container {
    padding-top: 0;
    padding-bottom: 0;
}

.profile__section-content {
    margin-bottom: 0;
}

.profile__section-content {
    border: none;
}