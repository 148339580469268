/*******************************/
/*          Login              */
/*******************************/

.login {
    /* position: fixed;
    z-index: 20;  
    top: 0; */
}

.login::after {
    content: '';
    position: fixed;
    min-height: 110vh;
    background-color: transparent;
    width: 100%;
    bottom: -10px;
    background-color: var(--black);  
    transition: all linear 0.2s;
}

.login.close::after {
    height: 0vh;
    bottom: 100%;
}

.login__image-container {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.login__background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.login__signup-credits {
    position: absolute;
    padding: 10px 15px;
    background:var(--secondary-colour);
    right: calc(50px);
    bottom: 50px;
    text-align: center;
}

.login-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.login__form-wrapper {
    margin: auto;
    padding: 15px 20px;
}

.login__btn-container {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.login__heading,
.login__intro {
    margin: 0 0 10px;
}

.login-form__footer {
    text-align: center;
}

.login-form__footer-copy {
    margin: 14px 0 !important;
}

.passwordMask a {
    margin-top: 0 !important;
    margin-right: 2px;
}

.login__form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 768px) {
    
}