/*******************************/
/*      Landing Screen         */
/*******************************/

#landing-page .rodal-dialog {
    border: 3px solid #bf9000;
    height: 150px !important;
    border-radius: 10px !important;
    opacity: 0.9 !important;
}

.landing-page {
    position: fixed;
    /* background: url('../../../src/assets/images/frontpage.jpg') no-repeat fixed center center; */
    background-size: cover;
    min-height: 100vh;
    background-color: var(--black);
    z-index: 10;
    width: 100%;
}

.landing-page__main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: var(--unit-four);
    background: var(--gold-001);
}

.text-for-hint {
    color: var(--grey-004);
    opacity: 0.8;
    margin-left: 10px;
    font-size: 18px;
    letter-spacing: 1px;
}

.bottom-text-container {
    color: var(--secondary-colour);
    font-size: 1.6em;
    line-height: 1.3em;
    font-weight: bold;
    margin: 0;
    padding-top: 45px;
    padding-bottom: 50px;
    letter-spacing: 1px;
}

.errorTextHeading {
    color: var(--error);
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    top: 5px;
    font-weight: bold;
}

.successTextHeading {
    color: var(--success);
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    float: left;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 2px;
}

.input-error-hightliter {
    background-color: #C6A36B;
    color: #FFF !important;
}

.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .5s linear;
    -o-transition: opacity .5s linear;
    transition: opacity .5s linear;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0s .5s, opacity .5s linear;
    -o-transition: visibility 0s .5s, opacity .5s linear;
    transition: visibility 0s .5s, opacity .5s linear;
}

.input-error-hightliter::-webkit-input-placeholder { 
    color: var(--secondary-colour);
    opacity: 1;
}

.input-error-hightliter:-ms-input-placeholder { 
    color: var(--secondary-colour);
    opacity: 1;
}

.input-error-hightliter::-ms-input-placeholder { 
    color: var(--secondary-colour);
    opacity: 1;
}

.input-error-hightliter::placeholder { 
    color: var(--secondary-colour);
    opacity: 1;
}

.maskedView {
    width: 100%;
    background-color: #404040;
    opacity: 0.7;
    height: 100%;
    position: fixed;
    margin-left: -10px;
    margin-top: -10px;
    text-align: center;
}

.maskedViewText {
    font-size: 3em;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    top: 40%;
    position: fixed;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.landing-page__middle-container,
.header-coming-soon__footer {
    padding: 10px 20px;
}

.header-coming-soon__footer {
    padding-bottom: 20px;
}

.landing-page__middle-header {
    margin-bottom: 20px;
}

.landing-page__middle-title {
    text-transform: uppercase;
    line-height: 4rem;
}

.landing-page__middle-subtext {
    font-family: Aparajita;
    /* font-style: italic; */
    font-size: 1.8rem;
    /* text-transform: capitalize; */
    margin: 30px 0;
    font-size: 1.4rem;
    font-family: var(--helvetica-regular);
}

.landing-page__middle-subtext--succss {
    text-transform: initial;
    font-style: italic;
    font-size: 16px;
}

.landing-page__social-media {
    display: flex;
    justify-content: space-around;
}

.landing-page__social-media-list {
    display: inline-block;
    margin: 0 2px 2px 0;
}

.landing-page__social-media-link {
    display: block;
    width: 20px;
}

.landing-page__social-media-link--small {
    width: 15px;
}

.landing-page__user-type {
    font-size: 18px;
}

.legend--user-type {
    position: absolute;
    text-transform: uppercase;
    bottom: -20px;
}

.legend-dark {
    background: var(--black);
    color: var(--secondary-colour);
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
    border: 0;
}

.legend-light {
    color: var(--black);
    background: var(--gold-001);
    padding: 5px 10px;
}

.eb-forms-open--landing-page {
    margin-bottom: 30px;
}

.btn-transparent--sign-in {
    margin-left: 20px;
}

.legend-gold {
    background: var(--gold);
    color: var(--secondary-colour);
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
}

@media screen and (max-width: 1200px) {
    .text-for-hint {
        margin: 10px 0 0;
        display: block;
    }

    .landing-page__main-container {
        width: var(--unit-five);
    }

}

@media screen and (max-width: 768px) {
    .landing-page__main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    .landing-page__main-container {
        width: var(--unit-eleven);
        margin: 20px auto;
    }

    .header-coming-soon__logo {
        max-height: 80px;
    }

    .bottom-text-container {
        font-size: 1.2em;
    }
    
    .header-coming-soon__header {
        padding: 10px 0 0 12px;
    }

    .btn-transparent--sign-in {
        margin: 0;
    }
}