/*******************************/
/*      Footer                 */
/*******************************/

.footer--open {    
    padding: 10px 0;
}

.footer__toggle {
    position: absolute;
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
    padding: 0;
    background: transparent;
    margin: 13px 20px;
    outline: none;
    cursor: pointer;
}

.footer__toggle-icon {
    transform: rotate(270deg);
    fill: var(--secondary-colour);
}

.footer--open .footer__toggle-icon {
    transform: rotate(90deg);
}

@-webkit-keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
}

.blink{
    text-decoration: blink;
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 0.6s;
    -webkit-animation-iteration-count:infinite;
    -webkit-animation-timing-function:ease-in-out;
    -webkit-animation-direction: alternate;
}

.footer__top {
    display: flex;
    flex-wrap: wrap;
    width: var(--unit-ten);
    margin: 0 auto;
    padding: 0 15px;
}

.footer__top-col {
    width: var(--unit-three);
    padding: 0 15px;
}

.footer__top-list {
    color: var(--secondary-colour);
    font-size: 1.2rem;
    margin-top: 20px;
    display: none;
}

.footer--open .footer__top-list {
    display: block;
}

.footer__top-list-item--inline {
    display: inline-block;
    padding: 0 5px;
}

.footer__top-list--util { margin-top: 15px }

.footer__top-list-item--util {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.footer__top-heading {
    text-transform: uppercase;
    color: var(--secondary-colour);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--grey-dark);
    padding-bottom: 5px;
    width: 45%;
    margin-bottom: 0 !important;
}

.footer__top-list-item i {
    font-size: 24px;
    margin: 0px 7px 4px 0;
}

.footer__top-heading-link {
    color: var(--secondary-colour);
    font-size: 14px;
}

.footer__top-link {
    letter-spacing: 1px;
    font-size: 1.2rem;
    line-height: 2.5rem;
    color: var(--secondary-colour);
}

.footer__top-link:hover,
.footer__top-link:focus,
.footer-links a:hover,
.footer-links a:focus { color: var(--gold) }

.footer__bottom {
    flex-direction: column;
    align-items: center;
    color: var(--secondary-colour);
    border-top: 1px solid var(--grey-light);
    padding: 0 15px;
    text-align: center;
    margin-top: 10px;
    display: none;
}

.footer--open .footer__bottom {
    display: flex;
}

.footer__bottom-heading {
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 0 !important;
}

.footer__social-media {
    display: flex;
    margin: 5px 0 10px;
}

.footer__social-media-link {
    margin: 0 3px 0 0;
    display: inline-block;
}

.footer__social-media-link img {
    width: 35px;
    height: 35px;
}

.footer-links a { color: var(--secondary-colour) }

@media screen and (max-width: 1200px) {
    .footer__top-heading {
        width: var(--unit-twelve);
    }
}

@media screen and (max-width: 768px) {
    .footer__top {
        width: var(--unit-twelve);
        padding: 0 40px;
    }

    .footer__top-col {
        width: var(--unit-six);
    }
}