/*******************************/
/*      rodal                  */
/*******************************/

.rodal-dialog {
    padding: 0;
}

.rodal__header{
    background-color: var(--gold-001);
    padding: 14px;
}

.rodal__main-container,
.rodal__btn-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
}

.rodal__btn-container {
    justify-content: space-between;
}

@media screen and (max-width: 1200px) {
}
  
@media screen and (max-width: 768px) {
}