/*********************/


/*    Login modal    */


/*********************/

.login-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: all ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
}

.login-modal--open {
    background: rgba(0, 0, 0, 0.7);
    transition: all ease-in-out 0.2s;
    opacity: 1;
    visibility: visible;
    z-index: 200;
}

.login-modal__container {
    width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--gold-001);
    padding: 20px;
}

.login-modal__header {
    margin-bottom: 10px;
}

.login-modal_step1_header {
    width: 100%;
}

.login-modal__title {
    text-align: center;
}

.login__title {
    margin-bottom: 10px;
}

.login-modal_title_step1 {
    margin-bottom: 10px !important;
    margin-top: -36px !important;
}

.login-modal__close-btn.btn {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    padding: 0;
    width: auto;
    background: none;
    height: auto;
    padding: 5px;
}

.login-modal__close-icon {
    width: 15px;
}

.login-modal__step1 {
    display: flex;
    padding: 20px 30px;
    flex-wrap: wrap;
    flex-direction: row;
}

.eb-forms .login-modal__choice.eb-radio {
    height: auto;
}

.eb-forms .login-modal__choice.eb-radio .checkmark {
    top: 100%;
    width: 50px;
    height: 50px;
    left: 50%;
    transform: translate(-50%, -100%);
}

.eb-forms .login-modal__choice.eb-radio .checkmark:after {
    top: 45%;
    width: 13px;
    height: 25px;
}

.eb-forms .login-modal__choice.eb-radio label {
    padding: 0 0 60px;
    text-align: center;
}

.login-modal-user-types .user-type__section {
    margin-bottom: 30px;
}

.new-login__legend.btn {
    height: 35px;
    border: 1px solid var(--gold);
    color: var(--gold-001);
    background: var(--gold);
    padding: 5px 15px;
}

.new-login__legend:disabled {
    background: var(--gold-001);
    color: var(--gold);
}

@media screen and (max-width: 768px) {
    .new-login__legend.btn {
        width: auto;
        display: inline;
    }
}

@media screen and (max-width: 767px) {
    .login-modal__container {
        width: calc(100% - 10px * 2);
        margin-left: 10px;
        margin-right: 10px;
        left: 0;
        transform: translate(0, -50%);
    }
    .login-modal__step1 {
        padding: 20px 10px;
    }
    .eb-forms .login-modal__choice.eb-radio label {
        padding-left: 30px;
        padding-right: 30px;
    }
}