.nunito-font {
    /* font-family: Nunito; */
    font-family: Helvetica;
}

.playfair-font {
    font-family: Playfair Display;
}

.contact-us-header-div {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 12px 30px;
    /* border-bottom: 1px solid var(--grey-light); */
    font-size: 16px;
    text-transform: uppercase;
    color: var(--gold);
    font-weight: bold;
    letter-spacing: 1px;
}

.contact-us-heading-container {
    margin: 3rem 0rem 0rem 3rem;
}

.contact-us-heading-text {
    font-weight: Bold;
    font-size: 40px;
}

.contact-us-sub-heading-container {
    margin: 0rem 0rem 0rem 0rem;
}

.contact-us-sub-heading-text {
    color: rgba(191, 144, 0, 0.7400000095367432);
    font-weight: Bold;
    font-size: 22px;
}

.contact-us-slogan-container {
    margin: 3rem 3rem 0rem 3rem;
    float: right;
}

.contact-us-slogan-text {
    font-weight: Bold;
    font-size: 36px;
}

.contact-us-box-with-shadow {
    display: flex;
    flex-direction: column;
    height: calc(91vh);
    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
    float: right;
    width: 75%;
}

.contact-us-image-container {
    align-self: flex-end;
    width: 840px;
    height: 545px;
    position: relative;
    right: 0px;
    box-shadow: -6px 4px 15px rgb(0 0 0 / 25%);
    overflow: hidden;
    float: right;
    margin: 6rem 0rem 0rem 0rem;
}

.contact-us-follow-us-container {
    margin: 2rem 0rem 0rem 0rem;
}

.contact-us-follow-us-text {
    font-weight: Bold;
    font-size: 30px;
}

.contact-us-follow-us-icons-container {
    margin-top: 4rem;
}

.icons {
    font-size: 30px;
}

.icon-margin-right {
    margin-right: 6rem;
}

.contact-us-form-container {
    margin-top: 5rem;
}

.form-control-margin-top {
    margin-top: 4rem;
}

.form-control-margin-left {
    margin-left: 3rem;
}

.form-control-margin-top-country {
    margin-top: 2rem;
}

.form-control-submit-button {
    float: right;
}

.icon-svg {
    height: 3rem !important;
    width: 4rem !important;
}


/*
@media only screen and (max-width: 959px) {
    .form-control-margin-left {
        margin-left: 0;
    }
    .form-control-submit-button {
        float: unset;
        margin-bottom: 4rem;
    }
    .contact-us-box-with-shadow {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .contact-us-box-with-shadow {
        width: 75%;
        height: calc(141vh);
    }
    .contact-us-image-container {
        width: 475px;
        height: unset;
        box-shadow: 20px -11px 15px 0px rgb(0 0 0 / 25%);
    }
}

@media only screen and (max-width: 1280px) {
    .contact-us-image-container {
        width: 618px;
        height: unset;
    }
    .contact-us-box-with-shadow {
        width: 75%;
        height: calc(96vh);
    }
}

@media only screen and (max-width: 1700px) {
    .contact-us-image-container {
        width: 550px;
    }
}

@media only screen and (max-width: 2000px) {
    .contact-us-image-container {
        width: 650px;
        height: unset;
    }
}

@media only screen and (max-width: 2200px) {
    .contact-us-image-container {
        width: 750px;
        height: unset;
    }
}

@media only screen and (max-width: 2560px) {
    .contact-us-box-with-shadow {
        width: 50%;
        height: calc(90vh);
    }
    .contact-us-form-container {
        width: 125%;
    }
}

*/

@media screen and (max-width:320px) {}

@media screen and (min-width:321px) and (max-width:639px) {}

@media screen and (max-width:959px) {
    .form-control-margin-left {
        margin-left: 0;
    }
    .form-control-submit-button {
        float: unset;
        margin-bottom: 4rem;
    }
    .contact-us-box-with-shadow {
        display: none;
    }
}

@media screen and (min-width:960px) and (max-width:1023px) {
    .contact-us-box-with-shadow {
        height: calc(85vh);
    }
    .contact-us-image-container {
        width: 550px;
        height: unset;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .contact-us-box-with-shadow {
        height: calc(124vh);
    }
    .contact-us-image-container {
        width: 450px;
        height: unset;
    }
}

@media screen and (min-width:1280px) and (max-width:1599px) {
    .contact-us-box-with-shadow {
        height: calc(96vh);
    }
    .contact-us-image-container {
        width: 620px;
        height: unset;
    }
}

@media screen and (min-width:1600px) and (max-width:1919px) {
    .contact-us-image-container {
        width: 765px;
        height: unset;
    }
}

@media screen and (min-width:1920px) and (max-width:2560px) {
    .contact-us-image-container {
        width: 920px;
        height: unset;
    }
}


/* @media screen and (min-width:1920px) {} */