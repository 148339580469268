/*******************************/
/*      Star Rating            */
/*******************************/

.rating { 
  border: none;
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

.fa-star {
    font-size: 24px !important;
    margin: 3px 2px;
}

.fa-star {
  color: var(--grey-003);
}

.fa-star.checked {
    color: var(--gold);
}

/****** Style Star Rating *****/

.rating-clickable {
  margin: 0;
  padding: 10px 0 0;
  border: none;
  float: left;
}

.rating-clickable-item { 
  border: none;
  float: right;
}

.rating-clickable-item > input { display: none; } 
.rating-clickable-item > label:before { 
  margin: 5px;
  font-size: 3.6rem;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating-clickable-item > label { 
    color: var(--grey-003);
    float: right; 
    cursor: pointer;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating-clickable-item > input:checked ~ label,
.rating-clickable-item ~ input:not(:checked) > label,
.rating-clickable-item.checked > input ~ label,
.rating-clickable-item:hover > label,
.rating-clickable-item:hover ~ .rating-clickable-item > label { color: var(--gold); }
.write-review__rate {
  width: 100%;
}

@media screen and (max-width: 1200px) { 
  
}

@media screen and (max-width: 768px) {

}