/*******************************/
/*   Supplier Header Screen    */
/*******************************/

.supplier-header {
    position: relative;
}

.supplier-header__cover-image-container {
    position: relative;
    height: calc(100vh - 400px);
    width: 100%;
    min-height: 400px;
}

.supplier-header__cover-image {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    width: 100%;
}

.supplier-header__info-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    background: rgba(0,0,0,0.2);
}

.supplier-header__logo {
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.supplier-header__info {
    display: flex;
    position: relative;
    align-items: flex-end; 
    padding: 10px;
    padding-left: 130px;
}

.supplier-header__logo-container {
    position: absolute;
    left: 0;
    top: 15%;
    padding: 0 20px 0 0;
}

.supplier-header__logo-container i {
    font-size: 130px;
    color: var(--secondary-colour);
    padding: 18px 15px;
}

.supplier-header__supplier-name,
.supplier-header__slogan {
    font-size: 2.5rem;
    line-height: 3rem;
    color: var(--secondary-colour);
    margin-bottom: 5px;
}

.supplier-header__slogan {
    font-size: 16px;
}

.supplier-header__pro {
    padding: 0 5px;
    background: transparent;
    color: var(--gold);
    border: 1px solid var(--gold);
    text-transform: uppercase;
    margin-right: 10px;
}

.supplier-header__star-container,
.supplier-header__review-link {
    color: var(--secondary-colour);
    font-size: 12px;
}

.supplier-profile__star-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 40%;
}

.supplier-header__star-container {
    display: flex;
}

.supplier-header__current-reviews, 
.supplier-header__review-link {
    margin: 1px 5px;
}

.supplier-header__current-reviews {
    border-right: 1px solid;
    padding-right: 10px;
}

.supplier-header__contact {
    background: var(--gold);
    color: var(--secondary-colour);
    border: none;
    padding: 0 15px;
    font-size: 1.4rem;
    width: 160px;
}

.supplier_profile-header-btn:hover {
    background: var(--gold) !important;
    color: var(--secondary-colour) !important;;
    border: none !important;;
    padding: 10px 15px !important;;
    font-size: 1.4rem !important;;
    width: 160px !important;;
}

.profile__other-information-section {
    width: 100%;
}

.profile__other-information-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile__other-information-section li {
    font-size: 14px;
}

@media screen and (max-width: 1200px) {
    .supplier-header__cover-image-container {
        height: calc(50vh - 250px);
    }
}

@media screen and (max-width: 768px) {
    .supplier-header__cover-image-container {
        height: calc(50vh - 150px);
        min-height: 200px;
    }

    .supplier-header__info {
        padding: 0 0 10px;
    }

    .supplier-header__logo-container {
        position: relative;
        padding: 0 10px 0 0;
    }

    .supplier-header__supplier-name {
        font-size: 22px;
    }

    .supplier-header__slogan {
        font-size: 14px;
    }

    .supplier-header__contact {
        padding: 10px 25px;
        font-size: 14px;
    }

    .supplier-header__logo {
        height: auto;
    }

    .supplier-header__star-container {
        flex-direction: column;
    }

    .supplier-header__current-reviews {
        border: none;
    }

    .supplier-profile__star-content {
        flex: 1 100%;
    }
}

@media screen and (max-width: 767px) {
    .supplier-header__cover-image-container { 
        height: 200px;
    }

    .supplier-header__info-container {
        position: relative;
        display: block;
        padding: 10px;
        background: rgba(0,0,0,0.4);
    }
}