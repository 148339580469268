/*******************************/
/*      Header                 */
/*******************************/

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.header__black-top-bar {
    position: fixed;
    background: var(--black);
    display: flex;
    width: var(--unit-twelve);
    justify-content: space-between;
    z-index: 2;
    left: 0;
    top: 0;
    background: var(--secondary-colour);
    border-bottom: 1px solid var(--black);
}

.header__black-top-bar-list {
    padding-right: 45px;
}

.header__black-top-bar-list-item {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    vertical-align: middle;
}

.header__black-top-bar-list-item:last-of-type {
    margin: 0;
}

.header__menu-list-item--close-icon {
    display: none;
    background: var(--black);
}

.header__black-top-bar-list-link {
    display: block;
    padding: 30px 7px;
    color: var(--black);
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
}

.header__black-top-bar-list-link:hover,
.header__black-top-bar-list-link:focus,
.header__black-top-bar-list-item--active {
    color: var(--gold);
    background: url('../../../assets/images/icons/triangle-up.png');
    background-repeat: no-repeat;
    background-position: center 25px;
}

.header__hamburger-button.btn {
    border: none;
    background: transparent;
    height: 100%;
    width: 60px;
    position: relative;
}

.header__hamburger-button.btn svg {
    font-size: 1.5em;
}

.header__hamburger-button.btn span {
    display: block;
    position: absolute;
    height: 1px;
    width: 80%;
    background: #17365d;
    opacity: 1;
    left: 50%;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    background: black;
    transform: translateX(-50%);
}

.header__black-top-bar-hamburger {
    height: 100%;
    padding: 12px;
    /* border-right: 1px solid var(--grey-004); */
    pointer-events: none;
}

.header__main-menu {
    position: sticky;
    top: 0;
    width: 85.4%;    
    z-index: 1;
    transition: all ease-in-out 0.2s;
    background: var(--secondary-colour);
}

.header__menu-list {
    display: flex;
    position: relative;
    justify-content: center;
}

.scrolled .header__main-menu {
    position: fixed;    
    transition: all ease-in-out 0.2s;
}

.scrolled .header__main-menu--open {
    border-bottom: 4px solid var(--black);
    top: 82px;
    transition: all ease-in-out 0.2s;
}

.header__menu-toggle {
    display: none;
    border: none;
    background: transparent;
}

.header__logo-container {
    margin-top: 50px;
    height: 95px;
    padding: 10px 0 0;
    width: 100%;
    text-align: center;
    background: var(--secondary-colour);
    display: none;
}

.header__logo {
    height: 100%;
    transition: all ease-in-out 0.2s;
}

.scrolled .header__logo {
    transition: all ease-in-out 0.2s;
    height: 90px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.header__scrolled-logo-container {
    height: 82px;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.5s;
    padding-left: 30px;
}

.header__scrolled-logo {
    height: 100%;
    padding: 10px 10px 0;
}

.scrolled .header__scrolled-logo-container {
    opacity: 1;
    visibility: visible;
    transition: all ease-in-out 0.5s;
    display: flex;
    align-items: center;
}

.header__menu-list-item {
    display: inline-block;
}

.header__menu-list-link {
    display: block;
    padding: 12px 65px;
    text-transform: uppercase;
    color: var(--black);
    margin-bottom: -1px;
    font-size: 1.5rem;
}

.header__menu-list-link.active,
.header__menu-list-link:hover,
.header__menu-list-link:focus {
    background: url('../../../assets/images/icons/active_tab.png') no-repeat center bottom;
} 

.header__menu-list-link:focus,
.header__menu-list-link:hover {
    color: var(--gold);
}

.header__menu-close {
    display: none;
    padding: 12px 24px;
    text-align: right;
    font-size: 12px;
}

.header__menu-side {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    /* border-left: 1px solid var(--grey-004); */
}

.header__button {
    border: none;
    background: transparent;
    padding: 12px;
}

.header__button-image {
    height: 100%;
}

.header__submenu-list {
    position: absolute;
    left: 0;
    width: var(--unit-twelve);
    background: var(--secondary-colour);
    padding: 5px 0;
    margin-top: 1px;
    transition: all 0.2s ease-out;
    transform: scale(1, 0);
    transform-origin: top;
    animation-fill-mode: forwards;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    background-clip: padding-box;
    text-align: center;
}

.header__menu-list-item:hover > .header__submenu-list {
    transform: scale(1, 1);
}

.header__submenu-list-item {
    display: inline-block;
}

.header__submenu-list-link {
    padding: 3px 20px;
    text-transform: capitalize;
    color: var(--black);
    font-size: 1.4rem;
    font-weight: bold;   
    cursor: pointer; 
}

.header__submenu-list-link:hover,
.header__submenu-list-link:focus {
    color: var(--gold);
    background: url('../../../assets/images/icons/sub_link_hover.jpg') no-repeat center bottom;
} 

.eb-forms--country-popover > select {
    width: 100%;
    min-width: 175px;
    margin: 0;
}

.country-searchable-dropdown {
    font-size: 12px !important;
    letter-spacing: 1px !important;
    font-weight: bold !important;
    color: #000 !important;
    margin-right: 10px;
}

.container input[type="text"] {
    height: 15px;
    min-width: 150px;
    margin-bottom: 0;
    cursor: pointer;
}

.country-searchable-dropdown__indicators { display: none !important }

.header__search-bar {
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
    border-top: 1px solid var(--grey-light);
    border-bottom: 1px solid var(--grey-light);
    background-color: var(--secondary-colour);
    transition: all ease-in-out 0.2s;
    padding: 3px 0;
    left: 0;
    right: 0;
}

.header__search-bar--open {
    z-index: 2;
    top: 100%;
    transition: all ease-in-out 0.2s;
}

.scrolled .header__search-bar--open {
    top: 100%;
    transition: all ease-in-out 0.2s;    
}

.header__input-container {
    width: var(--unit-six);
    margin: auto;
    font-size: 1.2rem;
}

.header__search-input {
    width: 100%;
    height: 30px;
    border-radius: 24px;
    border: 1px solid var(--grey-light);
    padding: 6px 20px;
}

.header__search-input:focus {
    outline: none;
}

.header__search-bar-button {
    border: none;
    padding: 0;
    width: auto;
}

.header__search-bar-close-icon {
    height: 100%;
    padding: 15px;
}

.profile__loader-container span {
    color: #fff;
    text-align: center;
    font-size: 20px;
    left: 50px;
    position: relative;
}

.header__profile-progress-btn {
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    border: none;
    padding: 0;
}

.header__profile-progress-btn:focus {
    outline: none;
}

.header__profile-progress {
    height: 100%;
    display: flex;
    text-align: center;
    position: relative;
}

.header__profile-progress .conic {
    width: 30px;
    height: 30px;
}

.header__profile-progress-btn:hover .conic {
    transform: scale(1.2);
}

.header__profile-progress .conic.circle {
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(255,255,255,0.2);
}

.header__profile-progress .percentage-value {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    color: var(--secondary-colour);
    font-size: 8px;
    font-weight: bold;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.header__profile-progress-btn:hover .percentage-value {
    transform: translate(-50%, -50%) scale(1.2);
}

.header__popover {
    z-index: 100;
    padding: 20px;    
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    position: fixed !important;
    left: auto !important;
    right: 25px !important;
    top: 60px !important;
    background: white;
    transform: translate(0, 0) !important;
    max-width: 300px;
}

.header__popover:before, 
.header__popover:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    right: 25px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #efeff1;
    z-index: 1;
}

.header__popover:before {
    z-index: 2;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 20px solid #ffffff;
    right: 27px;
}

.header__progress-popover {
    position: relative;
}

.header__popover-header {
    text-align: center;
    padding: 0 40px 10px;
    color: var(--gold);
}

.header__popover-title {
    margin-bottom: 10px !important;
}

.header__popover-divider {
    width: 100%;
}

.linear-progress {
    padding: 10px 0;
}

.linear-progress__percentage {
    font-size: 14px !important;
    font-weight: bold !important;
    color: var(--gold);
}

.linear-progress_progress-bar {
    height: 10px !important;
    border-radius: 4px;
    background-color: #efeff1 !important;
}

.linear-progress_progress-bar .MuiLinearProgress-barColorPrimary {
    background-color: var(--gold) !important;
}

.header__popover-content {
    padding-top: 10px;
    z-index: 105;
}

.header__popover-check .checkmark {
    border-radius: 50%;
}

.header__popover-check.eb-checkbox > .checkmark:after {
    display: block;
}

.header__popover-check.header__popover-check--checked .checkmark {
    background-color: var(--gold);
}

.header__popover-link {
    color: var(--black);
    font-weight: bold;
    font-size: 14px;
    flex: 1 1;
    padding: 0 0 0 40px;
    text-align: left;
}

.header__popover-check.header__popover-check--checked .header__popover-link {
    text-decoration: line-through;
}

.header__popover-footer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.image-gallery-bullets .image-gallery-bullets-container {
    line-height: normal;
}

.header__hamburger-icon path {
    fill: #C4C4C4
}

@media screen and (max-width: 1200px) {
    .header__menu-list-link {
        padding: 12px 45px;
    }   
}

@media screen and (max-width: 768px) {
    .header__black-top-bar,
    .scrolled .header__black-top-bar {
        position: relative;
        z-index: 1;
        border: none;
    }

    .header__logo {
        padding: 10px 0 0;
    }

    .header__main-menu {
        position: unset;
        justify-content: space-between;
        padding: 0;
    }

    .header__menu-list {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        height: 100vh;
        width: 80vw;
        overflow-y: auto;
        background: var(--grey-004);
        transform: translateX(-100vw);
        transition: transform ease-in-out .2s;
        z-index: 3;
    }

    .header__menu-list--open {
        transform: translateX(0);
        transition: transform ease-in-out .2s;
    }

    .header__menu-list-item {
        display: block;        
        border-top: 1px solid var(--black);
    }

    .header__menu-list-item--close-icon {
        display: block;
    }

    .header__menu-list-link {
        color: var(--secondary-colour);
        margin-bottom: 0;
        position: relative;
        padding: 12px 24px;
        text-align: left;
        font-size: 12px;
    }

    .header__menu-dropdown-button {
        position: absolute;
        right: 0;
    }

    .header__menu-dropdown-button:before {        
        content: '\f078';
        font-family: 'FontAwesome';
        display: block;
        height: 20px;
        width: 20px;
        color: var(--secondary-colour);
        transform: translateY(50%) rotate(0);
        transition: transform ease-in-out .2s;
    }

    .header__menu-dropdown-button--open::before{
        transform: rotate(180deg);
        transition: transform ease-in-out .2s;
    }

    .header__menu-list-link.active {
        background-image: none;
        color: var(--gold);
    }

    .header__menu-side,
    .header__menu-toggle  {
        height: auto;
        top: 50%;
    }

    .header__menu-side {
        position: relative;
    }

    .header__menu-toggle {
        display: block;
        position: absolute;
        left: 0;
        transform: translateY(-50%);
    }
    
    .header__button {
        padding: 0;
    }

    .header__button-image {
        padding: 15px;
    }

    .header__menu-close {
        display: block;
        color: var(--secondary-colour);
        background: transparent;
        border: none;
    }

    .header__black-top-bar-list-item:not(:last-of-type) {
        margin-right: 10px;
    }

    .header__black-top-bar-list-link {
        font-size: 7px;
        padding: 9px 0;
    }

    .header__submenu-list {
        position: relative;
    }

    .header__menu-list-link.active, 
    .header__menu-list-link:hover, 
    .header__menu-list-link:focus {
        background: none;
    }

    .header__black-top-bar-list {
        /* display: flex;
        justify-content: space-between; */
        padding: 0 10px;
        /* flex: 1; */
        position: absolute;
        right: 0;
    }

    .header__scrolled-logo-container {
        display: none;
    }

    .scrolled .header__scrolled-logo-container {
        display: none;
    }

    .header__input-container {
        width: var(--unit-ten);
    }

    .header__logo-container {
        display: flex;
        position: relative;
        width: 100%;
        padding: 0;
        height: 40px;
        margin: 0;
    }

    .scrolled .header__main-menu {
        position: relative;
        /* border-bottom: 4px solid var(--black); */
    }

    .header__logo-container a {
        text-align: center;
    }

    .header__popover {
        right: 0 !important;
    }

    .header__popover:before, 
    .header__popover:after {
        right: 15px;
    }

    .header__popover:before {
        right: 17px;
    }

    .header__black-top-bar .header__logo {
        display: none;
    }

    .scrolled .header__logo {
        height: 30px;
        padding: 0;
    }

    .header__mobile-hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__mobile-hamburger .fitler__heading {
        font-size: 8.5px;
        padding-left: 30px;
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .header__popover {
        right: auto !important;
        margin: 0 auto;
        left: 40% !important;
        transform: translateX(-40%) !important;
    }
}

.header__menu-list-item .active {
    color: var(--gold);
}

.header__submenu-list .active {
    color: var(--gold);
}

.static-page, 
.main {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
}

.static-page {
    min-height: 500px;
}

li {
    font-size: 12px;
}

.supplier_profile-header-btn {
    padding: 10px 20px;
    color: #fff;
}

.supplier_profile-header-btn:hover {
    padding: 10px 20px;
    color: #000;
}

.supplier-header__current-reviews-overrided {
    border-right: none
}

b, strong {
    font-weight: bolder;
    font-size: large;
}

.hight__lighted-text-underlined {
    color: var(--gold);
}

.heading__overrided-styles {
    padding-top:10px;
}

.customIconsStyle {
    position: relative;
    top: 3px;
    left: -5px;
    fill: currentColor !important;
    width: 30px !important;
    height: 18px !important;
}

a {
    text-decoration: none;
    color: var(--gold);
    font-size: 12px;
}