.previewComponent {
    position: relative;
    border: 1px dashed var(--grey-010);
    border-radius: 5px;
    margin-bottom: 10px;
    height: 100%;
    background-image: url('../../assets/images/f6f5f0.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.previewComponent svg {
    width: 40px;
    height: 40px;
    top: 5px;
    margin-right: 10px;
    z-index: 10;
    position: absolute;
    top: 5px;
    left: 5px;
    background: #f6f5f0;
    padding: 10px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
}

.imgPreview { 
    cursor: pointer; 
}

.imgPreview img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    left: 0;
    top: 0;
}

.ril__caption, .ril__toolbar {
    background-color: rgba(0, 0, 0, 1);
}