/*******************************/
/*   Supplier Profile Screen    */
/*******************************/

.filter--supplier-profile {
    font-size: 14px;
    color: var(--grey-dark);
    padding: 10px 20px;
}

.supplier-profile__tabs {
    padding-left: 140px;
}

.supplier-profile__list {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0 15px;
    text-transform: capitalize;
}

.supplier-profile__list:first-of-type {
    margin-left: 0;
}

.supplier-profile__list--active,
.supplier-profile__list:hover,
.supplier-profile__list:focus {
    color: var(--gold);
}

.supplier-profile__list--active:after {
    content: '';
    border-bottom: 5px solid var(--gold);
    position: absolute;
    width: 100%;
    bottom: -12px;
    left: 0;
}

.supplier-profile__number{
    margin: 0 15px;
}

.supplier-profile__contact-details {
    order: 2;
    display: flex;
    align-items: center;
    width: var(--unit-two-half);
}

.supplier-profile__contact-details .customIconsStyle {
    left: 0;
}

.supplier-profile__website {
    display: flex;
}

.supplier-profile__contact-details img {
    width: 20px;
    margin: 0 5px;
}

.supplier-profile__overview {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 100px;
}

.supplier-profile__description {
    width: var(--unit-nine-half);
    padding: 0 10px;
}

.supplier-profile__sidebar {
    width: var(--unit-two-half);
    padding: 0 10px;
}

.supplier-profile__sidebar p,
.supplier-profile__address {
    margin-top: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey-005);
    font-style: normal;
}

.supplier-profile__address {
    margin-bottom: 16px;
}

.supplier-profile__save,
.supplier-profile__write-review {
    background: var(--gold);
    color: var(--secondary-colour);
    border: none;
    padding: 0 15px;
    font-size: 1.4rem;
    min-width: 100px;
    margin-bottom: 15px;
}

.supplier-profile__social-media {
    margin: 10px 0;
}

.supplier-profile__social-media-list {
    display: inline-block;
    margin: 0 5px 5px 0;
}

.supplier-profile__social-media-link {
    display: block;
    width: 30px;
}

.supplier-profile__reviews-container {
    padding: 30px;
    /* margin: 0 auto; */
}

.supplier-profile__reviews-header {
    display: flex;
    justify-content: space-between;
}

.supplier-profile__reviews-heading {
    color: var(--grey-dark);
}

.supplier-profile__review {
    border-bottom: 1px solid var(--grey-light);
    width: calc(50% - 10px);    
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    margin: 0 20px 20px 0;
    padding: 10px 15px;
}

.supplier-profile__review:nth-of-type(2n) {
    margin-right: 0;
}

.supplier-profile__review-company-logo {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.supplier-profile__review-company-logo-image {
    object-fit: cover;
    object-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
}

.supplier-profile__review-header {
    display: flex;
    padding: 0 0 15px;
    border-bottom: 1px solid var(--grey-light);
    margin-bottom: 10px;
}

.supplier-profile__company-details {
    padding-left: 15px;
}

.supplier-profile__review-name {
    margin-bottom: 5px !important;
}

.supplier-profile__review-comment {
    position: relative;
    margin: 0;
    padding: 10px 40px;
    font-size: 12px;
}

.supplier-profile__review-comment:before,
.supplier-profile__review-comment:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: url('../../../../assets/images/icons/quote.svg') no-repeat center center;
}

.supplier-profile__review-comment:before {
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
    transform: rotate(180deg);
}

.profile__basic-infomation-labeles {
    color: var(--gold);
    font-weight: bold;
    font-size: 14px;
    min-width: 200px;
    width: 200px;
    font-style: normal;
}

.panel__label {
    font-size: 12px;
}

.rich-text, .rich-text p {
    font-size: 12px;
}

.panel__inner {
    padding-top: 10px;
}

.panel[aria-expanded='true'] .panel__content {
    font-size: 12px;
    line-height: 18px;
}

.supplier-profile__reviews-container .rodal__main-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    overflow-y: auto;
    height: 540px;
}

.overrided__tips-section {
    text-align: right;
    width: 100%;
}

td {
    font-size: 14px;
}

.each__review-header {

}

.supplier__telephone-container .react-tel-input .form-control {
    width: auto;
    cursor: default;
    border: none;
    background: none;
    height: auto;
    padding-left: 30px;
    color: var(--gold);
    pointer-events: none;
}

.supplier__telephone-container .react-tel-input .flag-dropdown {
    border: none;
    background: transparent;
}

.react-tel-input .selected-flag {
    padding-left: 10px;
}

.supplier__telephone-container .react-tel-input .selected-flag .arrow {
    display: none;
}

.supplier-profile__write-review-container {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 0 0;
}

.tip-text__container {
    word-break: break-all;
    text-align: justify;
}

@media screen and (max-width: 1024px) {
    .supplier-profile__description {
        width: var(--unit-eight-half);
    }

    .supplier-profile__sidebar,
    .supplier-profile__contact-details {
        width: var(--unit-three-half);
    }
}

@media screen and (max-width: 1000px) {
    .supplier-profile__reviews-container {
        padding: 30px 20px;
        width: var(--unit-twelve);
    }
}

@media screen and (max-width: 768px) {
    .filter--supplier-profile {
        padding: 10px 20px;
    }

    .supplier-profile__tabs {
        display: flex;
        justify-content: space-between;
    }

    .supplier-profile__number,
    .supplier-profile__website {
        margin-left: 0;
    }

    .supplier-profile__contact-details {
        display: none;
    }

    .supplier-profile__overview {
        display: block;
        padding: 20px;
    }

    .supplier-profile__description {
        padding: 0 0 30px;
        width: var(--unit-twelve);
    }

    .supplier-profile__tabs {
        padding-left: 0;
    }

    .supplier-profile__sidebar {
        width: var(--unit-twelve);
        padding: 0;
    }

    .supplier-profile__save {
        padding: 10px 25px;
    }

    .supplier-profile__reviews-header {
        display: block;
    }

    .supplier-profile__write-review {
        padding: 10px 25px;
    }

    .supplier-profile__reviews-container,
    .supplier-profile__overview {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 767px) {
    .supplier-profile__review {
        width: 100%;
        margin: 0 0 20px;
    }

    .supplier-profile__review-header {
        flex-wrap: wrap;
    }

    .supplier-profile__company-details {
        padding-left: 5px;
    }
}