/*******************************/


/*   Write Review Screen       */


/*******************************/

.rodal--write-review .rodal-dialog {
    width: 600px !important;
    border-radius: 5px;
    min-height: 610px;
    overflow-y: auto;
}

.eb-forms--write-review {
    flex: 1;
}

.write-review__tips-section {
    width: var(--unit-four);
    padding-left: 20px;
}

.write-review__tips-container {
    border: 1px solid var(--grey-light);
    margin-bottom: 50px;
    padding-bottom: 10px;
    ;
}

.write-review__tips-header {
    padding: 15px;
    border-bottom: 1px solid var(--grey-light);
}

.write-review__tips-list,
.write-review__tip-description {
    padding: 0 15px;
    font-size: 1.2rem;
    margin: 5px 0;
}

.write-review__tips-list-item {
    position: relative;
    margin-bottom: 5px;
    padding-left: 20px;
}

.write-review__tips-list-item:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid var(--gold);
    border-radius: 100%;
    left: 0;
    top: 6px;
}

.write-review__header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
}

.write-review__logo-container {
    width: 80px;
}

.write-review__logo {
    border: 1px solid;
    border-radius: 100%;
}

.write-review__rate {
    margin-left: 10px;
}

.eb-forms--write-review .eb-forms__form-group {
    flex-direction: column;
    align-items: flex-start;
}

.eb-forms--review-not-fullwidth {
    display: flex;
    width: var(--unit-twelve);
}

.write-review__form-col {
    padding-right: 10px;
    flex: 1;
}

.write-review__form-col:last-of-type {
    padding: 0;
}

.eb-forms .eb-forms__form-group--write-review select {
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.eb-forms__form-group--write-review select:nth-of-type(even) {
    margin-top: 10px;
}

.write-review__verification {
    background-color: #fff2b8;
    margin: 10px 0;
    padding: 10px;
    font-weight: bold;
}

.write-review__verification p {
    margin-bottom: 10px;
}

.write-review__verification .eb-forms__form-group--write-review:last-of-type {
    margin-bottom: 0;
}

.eb-forms__form-group--review-terms {
    margin-bottom: 0;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.90) !important;
}

@media screen and (min-width: 1400px) {
    .rodal--write-review .rodal-dialog {
        width: 800px !important;
    }
}

@media screen and (max-width: 1200px) {
    .rodal--write-review .rodal-dialog {
        width: 75vw !important;
        height: 75vh !important;
        border-radius: 5px;
        max-height: 600px;
    }
}

@media screen and (max-width: 768px) {}