.snackbars span {
    font-size: 12px;
    letter-spacing: 1px;
}

.error div[class*='MuiSnackbarContent-root'] {
    background-color: #DC143C;
}

.info div[class*='MuiSnackbarContent-root'] {
    background-color: #606060;
}

.warning div[class*='MuiSnackbarContent-root'] {
    background-color: #000000;
}

.success div[class*='MuiSnackbarContent-root'] {
    background-color: var(--gold);
}

.MuiSnackbarContent-message svg {
    position: relative;
    top: 2px;
    margin-right: 10px;
}

div[class*='MuiTypography-root'] span {
    position: relative;
}

span[class*='MuiIconButton-label'] {
    top: 0 !important;
}

span[class*='MuiIconButton-label']:focus {
    top: 0 !important;
}