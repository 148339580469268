    .bebas-font {
        /* @font-face {
    font-family: 'Bebas-Neue';
    src: url('../../../../assets/fonts/bebas-neue/BebasNeue-Regular.ttf');
} */
        font-family: 'Bebas Neue', cursive !important;
    }
    
    .letter-spacing-2 {
        letter-spacing: 2px;
    }
    
    .word-spacing-2 {
        word-spacing: 2px;
    }
    
    .xl-font-size {
        font-size: x-large;
    }
    /* @font-face {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/helvetica/Helvetica.ttf');
} */
    
    .send-message-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    
    .send-message-logo-container {
        width: 80px;
    }
    
    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        border-style: none;
    }
    
    .send-message-heading-container {
        width: 100%;
        margin-left: 10px;
    }
    
    .rodal-body-container {
        padding: 20px;
    }
    
    .margin-bottom-10 {
        margin-bottom: 10px !important;
    }
    
    .margin-top-10 {
        margin-top: 10px !important;
    }
    
    .margin-top-25 {
        margin-top: 25px !important;
    }
    
    .margin-bottom-30 {
        margin-bottom: 30px !important;
    }
    
    .margin-top-40 {
        margin-top: 40px !important;
    }
    
    .margin-top-20 {
        margin-top: 20px !important;
    }
    
    .send-message-send-button {
        background-color: #bf9000 !important;
        color: #fff !important;
        font-size: medium !important;
        width: 140px !important;
    }
    
    .checkbox-container {
        font-style: italic !important;
    }
    
    .send-message-end-credit {
        font-weight: bold !important;
        font-style: italic !important;
        text-transform: uppercase !important;
        font-size: 12px !important;
    }
    
    .select-box-error {
        color: red !important;
        margin-left: 12px !important;
    }
    
    .select-box-label {
        margin: -5px 0px 0px 12px;
    }
    
    .date-input-border {
        /* border: #c4c4c4 !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-radius: 4px !important;
        height: 48.85px !important; */
    }
    
    p#preferredDate-helper-text {
        margin-left: 12px;
        margin-top: 9px;
    }
    
    input#preferredDate {
        margin-left: 12px;
    }
    
    .contact-rodal__header {
        padding: 14px;
        background-color: #fff !important;
        color: #000;
        text-transform: uppercase;
        border-bottom: 1px solid;
    }
    
    .divider-fullwidth {
        width: 100%;
    }
    
    .rodal--contact {
        width: 800px !important;
        border-radius: 5px;
        min-height: 800px;
        overflow-y: auto;
    }
    
    .rodal-dialog.rodal-slideDown-enter {
        width: 900px !important;
        border-radius: 5px;
        min-height: 840PX;
        overflow-y: auto;
    }
    
    @media only screen and (max-width:769px) {
        .rodal-dialog.rodal-slideDown-enter {
            width: 700px !important;
            border-radius: 5px;
            min-height: 635PX;
            overflow-y: auto;
        }
    }
    
    @media only screen and (max-width:481px) {
        .rodal-dialog.rodal-slideDown-enter {
            width: 447px !important;
            border-radius: 5px;
            min-height: 615PX;
            overflow-y: auto;
        }
    }